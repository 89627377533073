var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-3"},[(!_vm.clientAccess || _vm.clientAccess.length == 0)?_c('Loader'):_vm._e(),(_vm.clientAccess.length > 0)?_c('div',{staticClass:"dashboard__container--body"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.clientAccess,"styleClass":"vgt-table condensed","search-options":{
        enabled: true,
        placeholder: 'Search this table',
      },"pagination-options":{
        enabled: true,
        mode: 'records',
        perPage: 50,
      }},on:{"on-row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'link')?_c('span',[_c('router-link',{attrs:{"to":`/clientaccess/` + props.row.id}},[_c('i',{staticClass:"fas fa-external-link ml-3 mr-3"})])],1):(props.column.field == 'name')?_c('span',[_vm._v(" "+_vm._s(props.row.firstName)+" "+_vm._s(props.row.lastName)+" ")]):(props.column.field == 'comp')?_c('span',_vm._l((props.row.company),function(item,index){return _c('span',[_vm._v(_vm._s(item.title)+" ")])}),0):(props.column.field == 'ven')?_c('span',_vm._l((props.row.venue),function(item,index){return _c('button',{staticClass:"chip mr-2"},[_vm._v(_vm._s(item.title)+" ")])}),0):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,3784472673)})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }